import React from 'react';
import { withPrefix } from 'gatsby';
import LazyHero from 'react-lazy-hero';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import HeroContentContainer from '../../components/HeroContentContainer';

const MoreInformation = ({ location }) => (
  <Layout bodyClass="page-how-to-apply" location={location} >
    <SEO title="About The Rise Prize" />
    <LazyHero className="hero--secondary" imageSrc={withPrefix("/heroes/how-to-apply.jpg")} isCentered={false} minHeight="40vh" opacity={0.2} color="#ffb082">
      <HeroContentContainer>
        <h1>About The Rise Prize</h1>
      </HeroContentContainer>
    </LazyHero>
    <div className="container pt-2 pt-md-6 pb-2 pb-md-4 faq-section">
      <div className="row justify-content-center">
        <div className="col-12">
          <h2 className="font-weight-bolder mb-2">Frequently Asked Questions</h2>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Who should apply to the Rise Prize?
              </h5>
            </div>
            <div className="col-12">
              <p>
                The Rise Prize is for US-based organizations of any age, size, or type that are currently working on direct impact initiatives for student parents as well as other students, for example:
              </p>
              <ul className="ul-orange mb-0">
                <li>Nonprofit organizations</li>
                <li>For-profit companies, including edtech solutions</li>
                <li>Higher education institutions, including departments or teams within higher education institutions; academic institution associations; or alternative career pathways programs, including workforce programs, credentialing programs, and bootcamps</li>
              </ul>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Can my organization apply even if the organization / solution is not specifically targeted towards student parents?
              </h5>
            </div>
            <div className="col-12">
              <p>
                Yes. We recognize that student parents are a population that intersects with many other populations, such as first-generation students, working adults, and justice-involved individuals.
              </p>
              <p>
                As long as your target population includes student parents and you are able to articulate in your application how your program will directly impact student parents, it will be a good fit for the Rise Prize.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                We have an idea but have yet to demonstrate any pilots or success, can we still apply?
              </h5>
            </div>
            <div className="col-12">
              <p>
                Yes! We encourage those with early-stage ideas to apply for the <strong>Early Stage</strong> application pool.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Can my organization apply even if I don&apos;t have a solution in place already?
              </h5>
            </div>
            <div className="col-12">
              <p>
              Yes, you can still apply—and we want you to apply! Your organization would need to have a new idea anchored in initial customer validation (user testing or pilots, market research, plan for customer validation, or a feasibility study). This could include:
              </p>
              <ul className="ul-orange mb-0">
                <li>Pre-seed and seed startups that have developed a plan for customer validation and research</li>
                <li>Established organizations exploring new ideas and solutions for student parents</li>
                <li>Academic institutions and social ventures at testing or pilot phases</li>
              </ul>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                We are a small organization but we have conducted customer validation, end-user testing, and market research. To which pool do we apply?
              </h5>
            </div>
            <div className="col-12">
              <p>
                Organizations with ideas that have demonstrated product/market fit and strength of the market as well as measured impact on customers/end users should apply in the <strong>Mature Stage</strong> prize pool. In cases where applicants did not select the correct application pool, Prize evaluators will reassign applicants following consultation.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                What conditions or reporting requirements are attached to winning the Rise Prize?
              </h5>
            </div>
            <div className="col-12">
              <p>
                No. Since the Rise Prize is awarded as prize money, there are no conditions or reporting requirements attached to winning the Rise Prize, but we hope to stay in touch on the impact of your solution.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Is there a project period / time period for completion of the project and funds after being awarded?
              </h5>
            </div>
            <div className="col-12">
              <p>
                No – since the funds are awarded as prize money, there are no spending requirements or project periods attached to the Rise Prize.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Can the funds be spent on scholarships / support funds for student parents in our program?
              </h5>
            </div>
            <div className="col-12">
              <p>
                Yes. The prize money may be used to provide scholarships to student parents.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Will the sponsors of the Rise Prize take an equity stake in the winning organizations?
              </h5>
            </div>
            <div className="col-12">
              <p>
                No - the funds are awarded as “no strings attached” prize funding. The Rise Prize team will not take any equity stake in the winning organizations.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                My organization is based outside of the US and serves student parents in other
                global regions. Can we apply?
              </h5>
            </div>
            <div className="col-12">
              <p>
                No, the challenge is intended for US-based organizations serving student parents domestically.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Can I apply as an individual?
              </h5>
            </div>
            <div className="col-12">
              <p>
                No. The Rise Prize is open to for-profit, non-profit organizations, and traditional and non-traditional postsecondary programs. Departments within postsecondary institutions are welcome to apply.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Is there a fee to submit our entry?
              </h5>
            </div>
            <div className="col-12">
              <p>
                No. There is no fee to submit an entry.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                We missed the submission deadline. Can we still apply?
              </h5>
            </div>
            <div className="col-12">
              <p>
                No. Unfortunately, applications not completed and submitted by the application deadline will not be accepted. All entry materials need to be submitted by May 17, 2020 at 11:59pm EST.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Can the application be completed in a language other than English?
              </h5>
            </div>
            <div className="col-12">
              <p>
                All answers must be written in English.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                We have had investment or a grant from Imaginable Futures, The Omidyar Group and/or Lumina—can we still apply?
              </h5>
            </div>
            <div className="col-12">
              <p>
                Yes!
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Will applications be made public?
              </h5>
            </div>
            <div className="col-12">
              <p>
                You will have the option to make your application public or private based on whether
                you would like to use the platform to network with other applicants.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Will finalists be able to choose their preferred advisor?
              </h5>
            </div>
            <div className="col-12">
              <p>
                No. However, we will match finalists with the advisor best fitted with the domain
                knowledge to help refine the solution.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Who will determine the winners?
              </h5>
            </div>
            <div className="col-12">
              <p>
                A team of subject-matter experts, who bring a range of perspectives to the table,
                will evaluate each entry. The judges include: Ascend Parent Advisors, experts in postsecondary
                education and economic mobility, and staff from Imaginable Futures, Lumina, and Entangled.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                When will the winners be announced?
              </h5>
            </div>
            <div className="col-12">
              <p>
                Winners will be notified July 3, 2020.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Will Imaginable Futures and Lumina take equity in my organization if we are awarded the prize?
              </h5>
            </div>
            <div className="col-12">
              <p>
                No. The prize money is meant to accelerate the most innovative solutions for student parents.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Are there any specific dates that applicants need to be available for?
              </h5>
            </div>
            <div className="col-12">
              <p>
                Yes. If you are selected as a finalist, you will be required to give a virtual pitch to our judges on June 24 and 25. Winners will be selected and notified on July 2, 2020.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                How will prizes be awarded?
              </h5>
            </div>
            <div className="col-12">
              <p>
                8 winners from the Early Stage category will be awarded $50,000 each.
              </p>
              <p>
                5 winners from the Mature Stage category will be awarded $200,000 each.
              </p>
              <p>
                1 winner for the Risers’ Choice will be awarded $100,000. Risers’ Choice winner will be selected by the Ascend PSP Parent Advisor judges.
              </p>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Why was our entry disqualified?
              </h5>
            </div>
            <div className="col-12">
              <p>
                Disqualification can happen for several reasons, including but not limited to:
              </p>
              <ul className="ul-orange">
                <li>Failing to meet submission requirements</li>
                <li>Failing to attend required events, such as the finalist video pitch session</li>
                <li>Violating the official rules, terms, or conditions of the Rise Prize</li>
                <li>Submitting content that is not original</li>
              </ul>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12">
              <h5 className="font-weight-bold">
                Can I speak with someone on the Rise Prize team to assess if my organization is a good fit for the Rise Prize?
              </h5>
            </div>
            <div className="col-12">
              <p>
                Unfortunately, due to the volume of requests, we cannot provide personalized support to applicants over the phone. However, our team is happy to answer any questions over email at <a className="text-blue" href="mailto:info@theriseprize.com" target="_blank" rel="noopener noreferrer">info@theriseprize.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid more-information-callout">
      <div className="container">
        <div className="row">
          <div className="col-12 px-lg-8 text-center">
            <p className="h3 font-weight-bold">
              Can&apos;t find the answer you&apos;re looking for?
            </p>
            <div className="mt-6 mb-2">
              <a
                className="btn btn-wide apply"
                href="mailto:info@theriseprize.com?subject=The Rise Prize"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Apply Now"
                onClick={() => {
                  trackCustomEvent({
                    category: "About The Rise Prize - Contact Button",
                    action: "Click",
                    label: `Contact Us`,
                  });
                }}
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default MoreInformation;
